// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@src/configs/axios'


export const getDraftCampaigns = createAsyncThunk('appCampaign/getDraftCampaigns', async () => {
  const response = await axios.get('v1/campaign')
  return response.data
})

export const getAllData = createAsyncThunk('appCampaign/getAllData', async () => {
  const response = await axios.get('v1/campaign/summary')
  return response.data
})

export const getCampaignData = createAsyncThunk('appCampaign/getCampaignData', async id => {
  if (!id)
  return []
  const response = await axios.get(`v1/campaign/id/${id}`)
  return response.data
})


export const getUserData = createAsyncThunk('appCampaign/getUserData', async id => {
  const response = await axios.get(`v1/campaign/summary/${id}`)
  return response.data
})

export const getCampaignDownload = createAsyncThunk('appCampaign/getCampaignDownload', async id => {
  const response = await axios.get(`v1/campaign/summary/download/${id}`)
  return response.data
})

export const getAllOrganisationData = createAsyncThunk('appCampaign/getAllOrganisationData', async () => {
  const response = await axios.get(`v1/sadmin/organisations`)
  return response.data
})

export const getOrganisationsCommunicationData = createAsyncThunk('appCampaign/getOrganisationsCommunicationData', async (id) => {
  if (id) {
  const response = await axios.get(`v1/sadmin/campaigns/${id}`)
  return response.data
  } return null;

})


export const getPeopleData = createAsyncThunk('appCampaign/getPeopleData', async id => {
  if (!id) return null;
  const response = await axios.get(`v1/contacts/${id}`)
  return response.data
})

export const getfreeEnrichPeopleData = createAsyncThunk('appCampaign/getfreeEnrichPeopleData', async id => {
  if (!id) return null;
  const response = await axios.get(`v1/callback/freeenrich/profile/${id}`)
  return response.data
})

export const postfreeEnrichPeopleData = createAsyncThunk('appCampaign/postfreeEnrichPeopleData', async (req) => {
  const response = await axios.post(`v1/callback/freeenrich/signup`, req)
  return response.data
})

export const postfreeEnrichResetData = createAsyncThunk('appCampaign/postfreeEnrichResetData', async () => {
  return null
})


export const getDINPeopleData = createAsyncThunk('appCampaign/getDINPeopleData', async id => {
  if (!id) return null;
  const response = await axios.get(`v1/contacts/din/${id}`)
  return response.data
})

export const getOpenPeopleData = createAsyncThunk('appCampaign/getOpenPeopleData', async id => {
  if (!id) return null;
  const response = await axios.get(`v1/callback/opencontact/${id}`)
  return response.data
})

export const updateOrganisationEmail = createAsyncThunk('appCampaign/updateOrganisationEmail', async (organisation) => {
  const response = await axios.post('v1/organisation/emails', organisation)
  return response.data
})

export const updateOrganisationCalendar = createAsyncThunk('appCampaign/updateOrganisationCalendar', async (organisation) => {
  const response = await axios.post('v1/organisation/calendar', organisation)
  return response.data
})

export const getEmailCode = createAsyncThunk('appCampaign/getEmailCode', async () => {
  const response = await axios.get('v1/organisation/emailcode')
  return response.data;
})

export const getExistingEmailProvider = createAsyncThunk('appCampaign/getExistingEmailProvider', async () => {
  const response = await axios.get('v1/organisation/emails')
  return response.data;
})

export const getExistingEmailProviderById = createAsyncThunk('appCampaign/getExistingEmailProviderById', async (id) => {
  
  const response = await axios.get(`v1/organisation/emails/${id}`)
  return response.data;
})

export const deactivateEmailProviderById = createAsyncThunk('appCampaign/deactivateEmailProviderById', async (id) => {
  const response = await axios.post(`v1/organisation/emails/deactivate`, id)
  return response.data;
})

export const warmupEmailProviderById = createAsyncThunk('appCampaign/warmupEmailProviderById', async (id) => {
  const response = await axios.post(`v1/organisation/emails/warmup`, id)
  return response.data;
})


export const getGmailConsent = createAsyncThunk('appCampaign/getGmailConsent', async () => {
  const response = await axios.get('v1/organisation/gmail')
  setTimeout(() => {
    window.open(response.data.data, '_blank');
})
  return response.data;
})

export const getMicrosoftConsent = createAsyncThunk('appCampaign/getMicrosoftConsent', async () => {
  const response = await axios.get('v1/organisation/microsoft')
  setTimeout(() => {
    window.open(response.data.data, '_blank');
})
  return response.data;
})

export const getCalendlyConsent = createAsyncThunk('appCampaign/getCalendlyConsent', async () => {
  const response = await axios.get('v1/organisation/calendar')
  setTimeout(() => {
    window.open(response.data.data, '_blank');
})
 return response.data;
})

export const updateCampaign = createAsyncThunk('appCampaign/updateCampaign', async (campaign) => {
  if (campaign) {
  const response = await axios.post('v1/campaign', campaign)
  return response.data
  } else return null;
})

export const deleteDraftCampaign = createAsyncThunk('appCampaign/deleteDraftCampaign', async (campaign) => {
  const response = await axios.post('v1/campaign/delete', campaign)
  return response.data
})

export const archiveCampaign = createAsyncThunk('appCampaign/archiveCampaign', async (campaign) => {
  const response = await axios.post('v1/campaign/archive', campaign)
  return response.data
})

export const archiveAutopilotCampaign = createAsyncThunk('appCampaign/archiveAutopilotCampaign', async (campaign) => {
  const response = await axios.post('v1/campaign/autopilot', campaign)
  return response.data
})

export const getCampaignFilter = createAsyncThunk('appCampaign/getCampaignFilter', async () => {
  const response = await axios.get('v1/campaign/filter')
  return response.data;
})

export const updateCampaignFilterCount = createAsyncThunk('appCampaign/updateCampaignFilterCount', async (obj) => {
  return obj;
})


export const getOrganisation = createAsyncThunk('appCampaign/getOrganisation', async () => {
  const response = await axios.get('v1/organisation')
  return response.data
})

export const updateOrganisation = createAsyncThunk('appCampaign/updateOrganisation', async (organisation) => {
  const response = await axios.put('v1/organisation', organisation)
  return response.data
})

export const getPeopleAllDetails = createAsyncThunk('appCampaign/getPeopleAllDetails', async id => {
  const response = await axios.get(`v1/communication/id/${id}`)
  return response.data
})

export const getAdminPeopleAllDetails = createAsyncThunk('appCampaign/getAdminPeopleAllDetails', async id => {
  const response = await axios.get(`v1/sadmin/campaigns/sub/${id}`)
  return response.data
})

export const archiveCommunication = createAsyncThunk('appCampaign/archiveCommunication', async (communication) => {
  const response = await axios.post('v1/communication/archive', communication)
  return response.data
})


export const sendManualEmail = createAsyncThunk('appCampaign/sendManualEmail', async (communication) => {
  const response = await axios.post('v1/communication/manualemail', communication)
  return response.data
})


export const updateDraftEmail = createAsyncThunk('appCampaign/updateDraftEmail', async (communication) => {
  const response = await axios.post('v1/communication/draftemail', communication)
  return response.data
})

export const getMyDetails = createAsyncThunk('appCampaign/getMyDetails', async () => {
  const response = await axios.get('v1/users')
  return response.data
})

export const postPayment = createAsyncThunk('appCampaign/postPayment', async (payment) => {
  const response = await axios.post('v1/payment/razorpay', payment);
  return response.data
})

export const postFinalPayment = createAsyncThunk('appCampaign/postFinalPayment', async (payment) => {
  const response = await axios.post('v1/payment/razorpay/update', payment);
  return response.data
})

export const getCreditHistory = createAsyncThunk('appCampaign/getCreditHistory', async () => {
  const response = await axios.get('v1/payment/credit');
  return response.data
})

export const uploadEnrichmentFile = createAsyncThunk('appCampaign/uploadEnrichmentFile', async (file) => {
  const response = await axios.post('v1/contacts/enrichment', file);
  return response.data
})

export const uploadAIEnrichmentFile = createAsyncThunk('appCampaign/uploadAIEnrichmentFile', async (file) => {
  const response = await axios.post('v1/contacts/aienrichment', file);
  return response.data
})

export const getEnrichmentFile = createAsyncThunk('appCampaign/getEnrichmentFile', async () => {
  const response = await axios.get('v1/contacts/enrichment/status');
  return response.data
})

export const getAIEnrichmentFile = createAsyncThunk('appCampaign/getAIEnrichmentFile', async () => {
  const response = await axios.get('v1/contacts/aienrichment/status');
  return response.data
})

export const getEnrichmentDownload = createAsyncThunk('appCampaign/getEnrichmentDownload', async (id) => {
  const response = await axios.get(`v1/contacts/enrichment/download/${id}`);
  return response.data
})

export const getEnrichmentFDownload = createAsyncThunk('appCampaign/getEnrichmentFDownload', async (id) => {
  const response = await axios.get(`v1/contacts/enrichment/fdownload/${id}`);
  return response.data
})

export const searchEnrichmentData = createAsyncThunk('appCampaign/searchEnrichmentData', async (id) => {
  const response = await axios.get(`v1/contacts/enrichment/search/${id}`);
  return response.data
})

export const requestUserPhone = createAsyncThunk('appCampaign/requestUserPhone', async (phone) => {
  const response = await axios.post(`v1/campaign/summary/phone`, phone)
  return response.data
})

export const getAdminRequestedPhone = createAsyncThunk('appCampaign/getAdminRequestedPhone', async () => {
  const response = await axios.get(`v1/sadmin/logs/comm`)
  return response.data
})

export const updateAdminComm = createAsyncThunk('appCampaign/updateAdminComm', async (phone) => {
  const response = await axios.post(`v1/sadmin/logs/commupdate`, phone)
  return response.data
})

export const getEmailTemplate = createAsyncThunk('appCampaign/getEmailTemplate', async () => {
  const response = await axios.get(`v1/emailtemplate`)
  return response.data
})

export const updateEmailTemplate = createAsyncThunk('appCampaign/updateEmailTemplate', async (template) => {
  const response = await axios.post(`v1/emailtemplate`, template)
  return response.data
})

export const updateCustomField = createAsyncThunk('appCampaign/updateCustomField', async (custom) => {
  const response = await axios.post(`v1/organisation/customfields`, custom)
  return response.data
})

export const updateCustomSignature = createAsyncThunk('appCampaign/updateCustomSignature', async (custom) => {
  const response = await axios.post(`v1/organisation/customsignatures`, custom)
  return response.data
})

export const getAIEmailTemplate = createAsyncThunk('appCampaign/getAIEmailTemplate', async (template) => {
  const response = await axios.post(`v1/emailtemplate/ai`, template)
  return response.data
})

export const getAIEmailPrompt = createAsyncThunk('appCampaign/getAIEmailPrompt', async (template) => {
  const response = await axios.post(`v1/emailtemplate/aiprompt`, template)
  return response.data
})

export const getContactSamples = createAsyncThunk('appCampaign/getContactSamples', async () => {
  const response = await axios.get(`v1/emailtemplate/contactsamples`)
  return response.data
})

export const deleteEmailTemplate = createAsyncThunk('appCampaign/deleteEmailTemplate', async (template) => {
  const response = await axios.post(`v1/emailtemplate/delete`, template)
  return response.data
})

export const getAdminCampaignList = createAsyncThunk('appCampaign/getAdminCampaignList', async (id) => {
  const response = await axios.get(`v1/sadmin/campaigns/organisations/${id}`)
  return response.data
})

export const getCampaignAdminStats = createAsyncThunk('appCampaign/getCampaignAdminStats', async () => {
  const response = await axios.get(`v1/sadmin/organisations/campaignstats`)
  return response.data
})

export const getOrgCampaignList = createAsyncThunk('appCampaign/getOrgCampaignList', async () => {
  const response = await axios.get(`v1/campaign/orgcampaign`)
  return response.data
})

export const updateRegeneration = createAsyncThunk('appCampaign/updateRegeneration', async (template) => {
  const response = await axios.post(`v1/sadmin/campaigns/regenerate`, template)
  return response.data
})

export const updateAdminOrgCredit = createAsyncThunk('appCampaign/updateOrgCredit', async (credit) => {
  const response = await axios.post(`v1/sadmin/organisations/credit`, credit)
  return response.data
})

export const getUpcomingCampaignEmail = createAsyncThunk('appCampaign/getUpcomingCampaignEmail', async () => {
  const response = await axios.get(`v1/communication/upcomingemail`)
  return response.data
})

export const getExternalProspect = createAsyncThunk('appCampaign/getExternalProspect', async () => {
  const response = await axios.get(`v1/extension/prospect`)
  return response.data
})

export const getOrgUsers = createAsyncThunk('appCampaign/getOrgUsers', async () => {
  const response = await axios.get('v1/users/teams/list')
  return response.data
})
export const createOrgUsers = createAsyncThunk('appCampaign/createOrgUsers', async (_user) => {
  const response = await axios.post(`v1/users`, _user)
  return response.data
})

export const validateOrgUsers = createAsyncThunk('appCampaign/validateOrgUsers', async (_user) => {
  const response = await axios.post(`v1/callback/user-validate`, _user)
  return response.data
})

export const deactivateOrgUsers = createAsyncThunk('appCampaign/deactivateOrgUsers', async (_user) => {
  const response = await axios.post(`v1/users/teams/deactivate`, _user)
  return response.data
})

export const updateOrgUsers = createAsyncThunk('appCampaign/updateOrgUsers', async (_user) => {
  const response = await axios.put(`v1/users`, _user)
  return response.data
})

export const updateOrgUsersCredit = createAsyncThunk('appCampaign/updateOrgUsersCredit', async (_user) => {
  const response = await axios.post(`v1/users/teams/credit`, _user)
  return response.data
})


export const getTrackProspect = createAsyncThunk('appCampaign/getTrackProspect', async () => {
  const response = await axios.get(`v1/extension/trackprospect`)
  return response.data
})

export const testOrganisationAnyEmail = createAsyncThunk('appCampaign/testOrganisationAnyEmail', async (organisation) => {
  const response = await axios.post('v1/organisation/emails/check', organisation)
  return response.data
})

export const getEmailConsents = createAsyncThunk('appCampaign/getEmailConsents', async (organisation) => {
  return organisation
})

export const appCampaignSlice = createSlice({
  name: 'appCampaign',
  initialState: {
    draftCampaign: [],
    data: [],
    udata: [],
    cdata: {},
    pdata: {},
    emailCode:{},
    existingEmail:[],
    existingCalendar:[],
    validateProvider:{},
    gmailConsent:{},
    campaignData:{},
    campaignFilter:[],
    campaignCount:{},
    organisationData:{},
    allPeopleData:{},
    allAdminPeopleData:{},
    loginData:{},
    paymentData:{},
    creditHistory:[],
    organisationListData:[],
    organisationsAllcommunicationData:[],
    enrichmentFile:[],
    enrichmentAIFile:[],
    enrichmentDownLoad:[],
    adminLogsUpdate:[],
    emailTemplatesData:[],
    emailTemplatesAIData:{},
    emailPromptAIData:{},
    contactSamplesData:[],
    campaignAdminListData:[],
    campaignOrgListData:[],
    upcomingEmailData:[],
    externalProspectData:[],
    orgUsersData:[],
    trackProspectData:{},
    campaignDownloadData:[],
    campaignAdminStatsData:{},
    emailConsentsData:"",
    searchEnrichmentData:[]
    
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDraftCampaigns.fulfilled, (state, action) => {
        state.draftCampaign = action.payload
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.udata = action.payload
      })
      .addCase(getCampaignData.fulfilled, (state, action) => {
        state.cdata = action.payload
        state.campaignData = action.payload
      })
      .addCase(getPeopleData.fulfilled, (state, action) => {
        state.pdata = action.payload
      })
      .addCase(getfreeEnrichPeopleData.fulfilled, (state, action) => {
        state.pdata = action.payload
      })
      .addCase(postfreeEnrichResetData.fulfilled, (state, action) => {
        state.pdata = action.payload
      })
      .addCase(getDINPeopleData.fulfilled, (state, action) => {
        state.pdata = action.payload
      })
      .addCase(getOpenPeopleData.fulfilled, (state, action) => {
        state.pdata = action.payload
      })
      .addCase(getEmailCode.fulfilled, (state, action) => {
        state.emailCode = action.payload
      })
      .addCase(getExistingEmailProvider.fulfilled, (state, action) => {

        state.existingEmail = action.payload.data?.filter(x => x.mode === 'Email');
        state.existingCalendar = action.payload.data?.filter(x => x.mode === 'Calendar');
      })

      .addCase(getExistingEmailProviderById.fulfilled, (state, action) => {
        state.validateProvider = action.payload
      })
      .addCase(getGmailConsent.fulfilled, (state, action) => {
        state.gmailConsent = action.payload
      })

      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.campaignData = action.payload
      })

      .addCase(getCampaignFilter.fulfilled, (state, action) => {
        state.campaignFilter = action.payload
      })

      .addCase(updateCampaignFilterCount.fulfilled, (state, action) => {
        state.campaignCount = action.payload
      })

      .addCase(getOrganisation.fulfilled, (state, action) => {
        state.organisationData = action.payload
      })

      .addCase(updateOrganisation.fulfilled, (state, action) => {
        state.organisationData = action.payload
      })

      .addCase(getPeopleAllDetails.fulfilled, (state, action) => {
        state.allPeopleData = action.payload
      })

      .addCase(getAdminPeopleAllDetails.fulfilled, (state, action) => {
        state.allAdminPeopleData = action.payload
      })

      .addCase(getMyDetails.fulfilled, (state, action) => {
        state.loginData = action.payload
      })

      .addCase(postFinalPayment.fulfilled, (state, action) => {
        state.paymentData = action.payload
      })

      .addCase(getCreditHistory.fulfilled, (state, action) => {
        state.creditHistory = action.payload
      })
      .addCase(getAllOrganisationData.fulfilled, (state, action) => {
        state.organisationListData = action.payload
      })
      .addCase(getOrganisationsCommunicationData.fulfilled, (state, action) => {
        state.organisationsAllcommunicationData = action.payload
      })
      .addCase(getEnrichmentFile.fulfilled, (state, action) => {
        state.enrichmentFile = action.payload
      })

      .addCase(getAIEnrichmentFile.fulfilled, (state, action) => {
        state.enrichmentAIFile = action.payload
      })

      .addCase(getEnrichmentDownload.fulfilled, (state, action) => {
        state.enrichmentDownLoad = action.payload
      })
      .addCase(getEnrichmentFDownload.fulfilled, (state, action) => {
        state.enrichmentDownLoad = action.payload
      })
      .addCase(requestUserPhone.fulfilled, (state, action) => {
        state.udata = action.payload
      })
      .addCase(getAdminRequestedPhone.fulfilled, (state, action) => {
        state.adminLogsUpdate = action.payload
      })
      .addCase(getEmailTemplate.fulfilled, (state, action) => {
        state.emailTemplatesData = action.payload
      })
      .addCase(updateCustomField.fulfilled, (state, action) => {
        state.organisationData = action.payload
      })
      .addCase(updateCustomSignature.fulfilled, (state, action) => {
        state.organisationData = action.payload
      })
      .addCase(getAIEmailTemplate.fulfilled, (state, action) => {
        state.emailTemplatesAIData = action.payload
      })
      .addCase(getAIEmailPrompt.fulfilled, (state, action) => {
        state.emailPromptAIData = action.payload
      })
      .addCase(getContactSamples.fulfilled, (state, action) => {
        state.contactSamplesData = action.payload
      })
      .addCase(getAdminCampaignList.fulfilled, (state, action) => {
        state.campaignAdminListData = action.payload
      })
      .addCase(getOrgCampaignList.fulfilled, (state, action) => {
        state.campaignOrgListData = action.payload
      })
      .addCase(getUpcomingCampaignEmail.fulfilled, (state, action) => {
        state.upcomingEmailData = action.payload
      })
      .addCase(getExternalProspect.fulfilled, (state, action) => {
        state.externalProspectData = action.payload
      })
      .addCase(getOrgUsers.fulfilled, (state, action) => {
        state.orgUsersData = action.payload
      })
      .addCase(getTrackProspect.fulfilled, (state, action) => {
        state.trackProspectData = action.payload
      })
      .addCase(getCampaignDownload.fulfilled, (state, action) => {
        state.campaignDownloadData = action.payload
      })
      .addCase(getCampaignAdminStats.fulfilled, (state, action) => {
        state.campaignAdminStatsData = action.payload
      })
      .addCase(getEmailConsents.fulfilled, (state, action) => {
        state.emailConsentsData = action.payload
      })
      .addCase(searchEnrichmentData.fulfilled, (state, action) => {
        state.searchEnrichmentData = action.payload
      })
  
  }
})

export default appCampaignSlice.reducer
